<template>
  <div>
    <div id="app">
      <app-skiplinks></app-skiplinks>
      <!-- Overlay pour le sidenav -->
      <div v-if="getIsSidenavVisible" class="full-screen-overlay" @click="closeSidenav"></div>
      <!-- Composants principaux -->
      <app-header></app-header>
      <app-titleBar></app-titleBar>
      <app-modal-comparison></app-modal-comparison>
      <app-sidenav v-show="getIsSidenavVisible" />
      <main role="main">
        <router-view />
      </main>
      <app-footer></app-footer>
    </div>
  </div>
</template>

<script>
import HeaderDsfr from "./components/Header.vue";
import FooterDsfr from "./components/Footer.vue";
import SkipLinksDsfr from "./components/SkipLinks.vue";
import Sidenav from "./components/Sidenav.vue";
import { mapGetters, mapActions } from "vuex";
import TitleBar from "./components/TitleBar.vue";
import ModalComparison from "./components/modals/ModalComparison.vue";

export default {
  components: {
    "app-sidenav": Sidenav,
    "app-header": HeaderDsfr,
    "app-titleBar": TitleBar,
    "app-footer": FooterDsfr,
    "app-skiplinks": SkipLinksDsfr,
    "app-modal-comparison": ModalComparison,
  },
  computed: {
    ...mapGetters({
      getIsSidenavVisible: "getIsSidenavVisible", // Getter pour afficher le sidenav
      isSmallScreen: "ui/isSmallScreen",
    }),
  },
  watch: {
    $route(to) {
      // Modification dynamique du titre de la page selon la route active
      document.title =
        to.meta.title ||
        "L’Observatoire des données des sites Internet de l’État";
    },
  },
  methods: {
    ...mapActions({
      setIsSidenavVisible: "setIsSidenavVisible",
      watchResize: "ui/watchResize", // Mapping correct de l'action
    }),

    // Fermer le sidenav lorsque l'overlay est cliqué
    closeSidenav() {
      this.setIsSidenavVisible(false);
    },
  },
  mounted() {
    // Activer la surveillance des redimensionnements à l'initialisation
    this.watchResize();
  },
};
</script>

<style>
@import "styles/global.scss";
</style>
