import Vue from 'vue'
import App from './App.vue'
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
import router from './router'
import store from "./store/store"

// Add component otherwise you get an error object string
import Header from './components/Header'
import Footer from './components/Footer'
import Navigation from './components/Navigation'
import config_file from './services/tarteaucitron_config.js'
import analytics_config_file from './services/dsfr_analytics_config.js'

// infobulle
import TooltipComponent from './components/TooltipComponent.vue'

import vueCustomElement from 'vue-custom-element'

require('../node_modules/@gouvfr/dsfr/dist/legacy/legacy.nomodule.min.js')
require('../node_modules/@gouvfr/dsfr/dist/dsfr.main.css')
require('../node_modules/@gouvfr/dsfr/dist/utility/utility.main.css')
require('../node_modules/@gouvfr/dsfr/dist/dsfr.css')
require('../node_modules/@gouvfr/dsfr/dist/utility/icons/icons-system/icons-system.min.css')


//DSFR Analytics
if (typeof Storage !== "undefined") {
    try {
        localStorage.setItem("tarteaucitron", "true");
        require('../public/tarteaucitron/tarteaucitron.js')
        require('../public/tarteaucitron/tarteaucitron.services.js')
        require('../public/tarteaucitron/css/dsfr-theme-tac.css')
        tarteaucitronForceLanguage = 'fr';
        tarteaucitron.init(config_file);
        tarteaucitron.user.eulerianHost = process.env.VUE_APP_TRACKING;
        window.dsfr = analytics_config_file;
    } catch (err) {
        console.warn("Cookies failed to be set; Blocked!" + err);
        try { //this is for brave browser
            localStorage.setItem("tarteaucitron", "false")
        } catch (err) {
        }
    }
}

require('../node_modules/@gouvfr/dsfr/dist/dsfr.module.js')
require('../node_modules/@gouvfr/dsfr/dist/scheme/scheme.module.min.js')
require('../node_modules/@gouvfr/dsfr/dist/analytics/analytics.module.js')


Vue.config.productionTip = false

if (process.env.VUE_APP_KEYCLOAK_AVAILABLE === 'true') {
    Vue.use(VueKeyCloak, {
        config: {
            realm: process.env.VUE_APP_KEYCLOAK_REALM,
            url: process.env.VUE_APP_KEYCLOAK_URL,
            clientId: process.env.VUE_APP_KEYCLOAK_CLIENT
        },
        init: {
            // Use 'login-required' to always require authentication
            // If using 'login-required', there is no need for the router guards in router.js
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri: window.location.origin + process.env.VUE_APP_PREFIX_PATH + '/silent-check-sso.html',
            // redirectUri: window.location.origin + process.env.VUE_APP_PREFIX_PATH + '/'
        }
    })
}

if (!Promise.allSettled) {
  Promise.allSettled = function (promises) {
    return Promise.all(promises.map(p => 
      Promise.resolve(p).then(
        value => ({ status: 'fulfilled', value }),
        reason => ({ status: 'rejected', reason })
      )
    ));
  };
}

Vue.use(vueCustomElement)

Vue.customElement('header-dsfr', Header)
Vue.customElement('footer-dsfr', Footer)
Vue.customElement('navigation-dsfr', Navigation)
Vue.customElement('tooltip-component', TooltipComponent)



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
