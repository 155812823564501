// ui.js

let isWatchingResize = false;
let resizeListener = null;

const mediaQuery = window.matchMedia("(max-width: 767px)");

const state = {
  isSmallScreen: mediaQuery.matches,
  isComparativeTag: false, // État initial
};

const mutations = {
  updateIsSmallScreen(state, matches) {
    state.isSmallScreen = matches;
  },
  SET_COMPARATIVE_TAG(state, payload) {
    state.isComparativeTag = payload;
  },
};

const actions = {
  watchResize({ commit }) {
    if (!isWatchingResize) {
      isWatchingResize = true;
      resizeListener = (event) => {
        commit("updateIsSmallScreen", event.matches);
      };
      // Vérifier la compatibilité du navigateur pour addEventListener
      if (typeof mediaQuery.addEventListener === "function") {
        mediaQuery.addEventListener("change", resizeListener);
      } else if (typeof mediaQuery.addListener === "function") {
        // Pour les anciens navigateurs
        mediaQuery.addListener(resizeListener);
      }
    }
  },
  stopWatchingResize() {
    if (isWatchingResize && resizeListener) {
      if (typeof mediaQuery.removeEventListener === "function") {
        mediaQuery.removeEventListener("change", resizeListener);
      } else if (typeof mediaQuery.removeListener === "function") {
        mediaQuery.removeListener(resizeListener);
      }
      isWatchingResize = false;
      resizeListener = null;
    }
  },
  toggleComparativeTag({ commit }, payload) {
    // Logique ici
    commit('SET_COMPARATIVE_TAG', payload);
  },
};


const getters = {
  isSmallScreen: (state) => state.isSmallScreen,
  getIsComparativeTag: (state) => state.isComparativeTag,
};

export default {
  namespaced: true,
  state: { isComparativeTag: false },
  mutations,
  actions,
  getters,
};
